import './css/bootstrap.min.scss'
import './css/meanmenu.scss'
import './css/owl.carousel.min.scss'
import './css/slick.scss'
import './css/main.scss'
import useAddScripts from '@/utils/dom/useAddScripts'
import { Helmet } from 'react-helmet'
import HubSpotForm from './HubSpotForm'
import { useUnsetBodyOverflow } from '@/app/hooks'
import { optiwattConsts } from '@/optiwattConsts'

function Header() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <title>Optiwatt Business</title>
      <meta name="description" content="" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link
        rel="stylesheet"
        href="landing-assets/business/css/fontawesome-all.min.css"
      />
    </Helmet>
  )
}

export default function BusinessLandingPage() {
  useAddScripts([
    'landing-assets/business/js/jquery.min.js',
    'landing-assets/business/js/bootstrap.bundle.min.js',
    'landing-assets/business/js/jquery.meanmenu.min.js',
    'landing-assets/business/js/main.js',
  ])

  useUnsetBodyOverflow('auto')

  return (
    <div
      id="business-landing-scope"
      className="_use-deprecated-style _use-deprecated-bootstrap"
    >
      <Header />
      <div className="sidebar-info side-info">
        <div className="sidebar-logo-wrapper mb-25">
          <div className="row align-items-center">
            <div className="col-xl-6 col-8">
              <div className="sidebar-logo">
                <a href="/home">
                  <img src="/img/logo/logo-colored-white.webp" alt="logo-img" />
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-4">
              <div className="sidebar-close-wrapper text-end">
                <button className="sidebar-close side-info-close">
                  <i className="fal fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="sidebar-menu-wrapper fix">
          <div className="mobile-menu"></div>
        </div>

        <div className="sidebar-contact-wrapper mt-40"></div>
      </div>
      <div className="offcanvas-overlay"></div>
      <div className="submit-popup">
        {/* <img src="landing-assets/business/img/popup-icon.png" alt="" /> */}
        <h1 className="popup-heading">Question Submitted</h1>
        <p>
          Thank you for sending your question. Our team will be in touch with
          you soon!
        </p>
        <span className="popup-close">Close</span>
      </div>

      <header>
        <div className="header-area">
          <div className="container">
            <div className="menu-wrap">
              <div className="header-logo">
                <a href="business">
                  <img
                    src="landing-assets/business/img/logo/logo.png"
                    alt="Brand logo"
                  />
                </a>
              </div>
              <nav
                className="main-menu mobile-menu d-sm-none d-md-block"
                id="mobile-menu"
              >
                <ul>
                  <li>
                    <a href="signup">Sign Up</a>
                  </li>
                  <li>
                    <a href="https://optiwatt.applytojob.com/">Career</a>
                  </li>
                  <li>
                    <a href="business/#contact">Contact</a>
                  </li>
                  <li>
                    <a href="faq">FAQ</a>
                  </li>
                </ul>
              </nav>
              <div className="menu-btn d-flex align-items-center">
                <div className="header-btns d-none d-md-flex">
                  <a href="login" className="header-login-btn">
                    login
                  </a>
                  <a href="signup" className="header-signup-btn">
                    Sign Up
                  </a>
                </div>
                <div className="menu-bar d-md-none">
                  <div className="navbar-sign side-toggle ml-20">
                    <span className="menu-line-1"></span>
                    <span className="menu-line-2"></span>
                    <span className="menu-line-3"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section className="banner-area">
          <div className="banner-shape">
            <img
              src="landing-assets/business/img/banner/banner-shape.png"
              alt=""
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-4 col-xl-4">
                <div className="banner-left">
                  <h1 className="banner-heading">
                    Customer Centric platform to manage
                    <span className="gradient-title">all DERs.</span>
                  </h1>
                </div>
              </div>
              <div className="col-md-8 col-lg-8 col-xl-8 d-flex justify-content-center align-items-center">
                <div className="banner-right">
                  <div className="energy-stats-block-wrap">
                    <img
                      src="landing-assets/business/img/energy-stats.svg"
                      alt="optiwatt-linked-with-utility-interests"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="our-client">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="section-heading">
                  <span style={{ color: 'black' }}>Our</span> <br />
                  <span className="gradient-title">clients</span>
                </h2>
                <div className="client-brand-wrap brand-wrap">
                  <div className="client-brand">
                    <img
                      src="landing-assets/business/img/client/1.png"
                      alt=""
                    />
                  </div>
                  <div className="client-brand">
                    <img
                      src="landing-assets/business/img/client/2.png"
                      alt=""
                    />
                  </div>
                  <div className="client-brand">
                    <img
                      src="landing-assets/business/img/client/4.png"
                      alt=""
                    />
                  </div>
                  <div className="client-brand">
                    <img
                      src="landing-assets/business/img/client/5.png"
                      alt=""
                    />
                  </div>
                </div>
                <a href="download" className="learn-about-btn">
                  App feedback + ratings
                  <i className="fas fa-chevron-right"></i>
                </a>
              </div>
              <div className="col-lg-6">
                <h2 className="section-heading">
                  <span style={{ color: 'black' }}>Our&nbsp;</span>
                  <br />
                  <span className="gradient-title">partners</span>
                </h2>
                <div className="patner-brand-wrap brand-wrap">
                  <div className="client-brand">
                    <img
                      src="landing-assets/business/img/client/6.png"
                      alt=""
                    />
                  </div>
                  <div className="client-brand">
                    <img
                      src="landing-assets/business/img/client/7.png"
                      alt=""
                    />
                  </div>
                  <div className="client-brand">
                    <img
                      src="landing-assets/business/img/client/8.png"
                      alt=""
                    />
                  </div>
                  <div className="client-brand">
                    <img
                      src="landing-assets/business/img/client/9.png"
                      alt=""
                    />
                  </div>
                  <div className="client-brand">
                    <img
                      src="landing-assets/business/img/client/10.png"
                      alt=""
                    />
                  </div>
                  <div className="client-brand">
                    <img
                      src="landing-assets/business/img/client/11.png"
                      alt=""
                    />
                  </div>
                  <div className="client-brand">
                    <img
                      src="landing-assets/business/img/client/12.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="callout">
                  <img
                    src="landing-assets/business/img/client/call-out-i.png"
                    alt="lock"
                    style={{ height: '18px' }}
                  />
                  <p className="callout-desc">
                    We are the largest EV managed charging platform in the US.
                    65k connected EVs and growing
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="feature">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="feature-text-content">
                  <h2 className="section-heading">
                    Scale DR programs and reach all customers in your area
                  </h2>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 p-s-none">
                <div className="feature-graphic">
                  <div className="feature-thum">
                    <img
                      src="landing-assets/business/img/all-feature/1.png"
                      alt="feature"
                    />
                  </div>
                  <img
                    className="f-block-img"
                    src="landing-assets/business/img/all-feature/b-1.png"
                    style={{ maxWidth: '418px' }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="all-feature-wrapper">
          <div className="feature-bg-shape">
            <img src="landing-assets/business/img/bg-shape-2.png" alt="" />
          </div>
          <section className="feature-2">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-7 order-2 order-md-0">
                  <div className="feature-2-graphic">
                    <img
                      src="landing-assets/business/img/all-feature/2.jpg"
                      alt=""
                    />
                    <img
                      className="f-block-img"
                      src="landing-assets/business/img/all-feature/b-2.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="feature-2-text-content">
                    <h2 className="section-heading">
                      Control DERs to your unique needs
                    </h2>
                    <p className="desc desc-1">
                      We manage to your load objectives
                    </p>
                    <p className="desc">
                      We will integrate all DERs with your existing system
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="feature-3 section-padding">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-5">
                  <div className="feature-3-text-content">
                    <h2 className="section-heading">
                      Understand your customer
                    </h2>
                    <p className="desc">
                      Understand EV load in your exact territory
                    </p>
                    <p className="desc">Reduce churn</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-7">
                  <div className="feature-3-graphic">
                    <img
                      src="landing-assets/business/img/all-feature/3.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="more-feature-1 more-feature section-padding">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 col-lg-3">
                  <div className="more-feature-tabs">
                    <h3 className="label">
                      Scale Your <br />
                      Business
                    </h3>
                    <h2 className="section-heading">
                      So much <br />
                      more to love
                    </h2>
                    <div className="more-feature-tabs-wrap tab-buttons">
                      <button
                        className="tab-btn active-tab-btn"
                        data-tab="tab-1"
                      >
                        New Customers
                      </button>
                      <button className="tab-btn" data-tab="tab-2">
                        New Channel
                      </button>
                      <button className="tab-btn" data-tab="tab-3">
                        New Value Prop
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-lg-9">
                  <div className="row align-items-center">
                    <div className="col-lg-8 tab-thum-wrap">
                      <div className="more-feature-thum tab-1 active-fade-content">
                        <img
                          src="landing-assets/business/img/more-feature/thum.png"
                          alt="Feature thum"
                        />
                      </div>
                      <div className="more-feature-thum tab-2">
                        <img
                          src="landing-assets/business/img/more-feature/thum-2.png"
                          alt="Feature thum"
                        />
                      </div>
                      <div className="more-feature-thum tab-3">
                        <img
                          src="landing-assets/business/img/more-feature/thum-3.png"
                          alt="Feature thum"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 text-contents">
                      <div className="callouts tab-1 active-fade-content">
                        <div className="callout-item">
                          <h4 className="callout-title">65k EVs</h4>
                          <p className="callout-desc">
                            Largest EV telematics platform in North America
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">4k Thermostats</h4>
                          <p className="callout-desc">
                            Reach net new customers outside of traditional OEM
                            channels
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">
                            USA Reach + Convergence
                          </h4>
                          <p className="callout-desc">
                            Targeted by zip code, home characteristics, and
                            program qualification
                          </p>
                        </div>
                      </div>
                      <div className="callouts tab-2">
                        <div className="callout-item">
                          <h4 className="callout-title">Influencer</h4>
                          <p className="callout-desc">
                            Tap into a network of influencers with 3M+ reach
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">Social media</h4>
                          <p className="callout-desc">
                            Reach customers where they spend their time online
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">Press Releases</h4>
                          <p className="callout-desc">
                            Get coverage for innovative, clean programs
                          </p>
                        </div>
                      </div>
                      <div className="callouts tab-3">
                        <div className="callout-item">
                          <h4 className="callout-title">Beyond incentives</h4>
                          <p className="callout-desc">
                            Insights and features within a mobile-first
                            application
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">Scheduling</h4>
                          <p className="callout-desc">
                            Allows customers to set EV schedules and trips
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">Cost savings</h4>
                          <p className="callout-desc">
                            Automatically optimize charging around dynamic rates
                            to increase savings
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="cta">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 col-lg-6">
                  <div className="cta-title-area">
                    <h3 className="cta-title">
                      Optiwatt Marketing <br />
                      Engine
                    </h3>
                    <p className="cta-des">Drive value where it matters</p>
                  </div>
                </div>
                <div className="col-md-7 col-lg-6">
                  <div className="cta-rating-wrap">
                    <p>
                      Personalized, targeted messaging to meet customers where
                      they are at
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="power-step">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="section-heading">Control anything</h2>
                  <div className="step-block-wrap">
                    <div className="single-block">
                      <p>
                        <img
                          src="landing-assets/business/img/banner/b-3.png"
                          alt="icon 1"
                          className="block-icon"
                        />
                        <span>EV</span>
                      </p>
                    </div>
                    <div className="single-block">
                      <p>
                        <img
                          src="landing-assets/business/img/banner/b-2.png"
                          alt="icon 1"
                          className="block-icon"
                        />
                        <span>Thermostat</span>
                      </p>
                    </div>
                  </div>
                  <p className="step-desc">coming soon</p>
                  <p className="step-desc">
                    Battery + Solar + Water Heater + V2g
                  </p>
                </div>
                <div className="col-lg-6"></div>
              </div>
              <div className="col-lg-8 ms-lg-auto manage-around">
                <h2 className="section-heading text-lg-end">
                  Manage around <br />
                  anything
                </h2>
                <div className="step-block-wrap">
                  <div className="single-block">
                    <p>Asset deferment</p>
                  </div>
                  <div className="single-block">
                    <p>Transformer safety</p>
                  </div>
                </div>

                <div className="step-block-wrap">
                  <div className="single-block">
                    <p>Demand response</p>
                  </div>
                  <div className="single-block">
                    <p>Peak arbitrage</p>
                  </div>
                  <div className="single-block">
                    <p>
                      TOU rate <br />
                      optimization
                    </p>
                  </div>
                </div>

                <div className="step-block-wrap">
                  <div className="single-block">
                    <p>Carbon reduction</p>
                  </div>
                  <div className="single-block">
                    <p>Emergency events</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 control-anything">
                <h2 className="section-heading">Control anything</h2>
                <div className="step-block-wrap">
                  <div className="single-block">
                    <p>Custom integration</p>
                  </div>
                  <div className="single-block">
                    <p>
                      90% of DRMs <br />
                      systems
                    </p>
                  </div>
                </div>

                <div className="step-block-wrap">
                  <div className="single-block">
                    <p>OpenADR</p>
                  </div>
                  <div className="single-block">
                    <p>Complex signals</p>
                  </div>
                  <div className="single-block">Wholesale rates</div>
                </div>

                <div className="step-block-wrap">
                  <div className="single-block">
                    <p>Shapefile</p>
                  </div>
                  <div className="single-block">
                    <p>Transformer load</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 ms-lg-auto text-md-end">
                <h2 className="section-heading text-lg-end">
                  Understand DER <br />
                  impact
                </h2>
                <p className="step-desc">100s of datapoints per customer</p>
                <p className="step-desc">
                  Learn and prepare your grid <br />
                  Identify charing behaviors
                </p>
                <p className="step-desc">Load shapes</p>
                <p className="step-desc">Away / at home charging</p>
              </div>
            </div>
          </section>
          <section className="more-feature more-feature-2 section-padding">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-5">
                  <div className="more-feature-tabs">
                    <h3 className="label">Engagement</h3>
                    <h2 className="section-heading">
                      Customers will <br />
                      love you
                    </h2>
                    <div className="more-feature-tabs-wrap tab-buttons">
                      <button
                        className="tab-btn active-tab-btn"
                        data-tab="f-tab-1"
                      >
                        Maximize Enrollments
                      </button>
                      <button className="tab-btn" data-tab="f-tab-2">
                        Maximize Participation / Reduce Churn
                      </button>
                      <button className="tab-btn" data-tab="f-tab-3">
                        Maximizing customer satisfaction
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-7">
                  <div className="row align-items-center">
                    <div className="col-lg-7 tab-thum-wrap">
                      <div className="more-feature-thum f-tab-1 active-fade-content">
                        <img
                          src="landing-assets/business/img/more-feature/thum.png"
                          alt="Feature thum"
                        />
                      </div>
                      <div className="more-feature-thum f-tab-2">
                        <img
                          src="landing-assets/business/img/more-feature/thum-2.png"
                          alt="Feature thum"
                        />
                      </div>
                      <div className="more-feature-thum f-tab-3">
                        <img
                          src="landing-assets/business/img/more-feature/thum-3.png"
                          alt="Feature thum"
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 text-contents">
                      <div className="callouts f-tab-1 active-fade-content">
                        <div className="callout-item">
                          <h4 className="callout-title">
                            Seemless onboarding and connection
                          </h4>
                          <p className="callout-desc">
                            Users can easily connect 20+ OEMs and 70+ models
                            without needing any additional hardware
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">Robust insights</h4>
                          <p className="callout-desc">
                            Provide valuable insights and tips that help
                            customers better understand and manage their bill
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">Rewards to engage</h4>
                          <p className="callout-desc">
                            Offer and easily process rewards and incentives to
                            encourage program participation
                          </p>
                        </div>
                      </div>
                      <div className="callouts f-tab-2">
                        <div className="callout-item">
                          <h4 className="callout-title">Push notifications</h4>
                          <p className="callout-desc">
                            Notify users about upcoming events, localized load
                            stress and upcoming new programs
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">Customer support</h4>
                          <p className="callout-desc">
                            Optiwatt is the highest rated residential energy
                            management application (4.7 stars across 3,189
                            reviews).
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">
                            Personalized settings
                          </h4>
                          <p className="callout-desc">
                            Customers can set their EV Ready By times,
                            thermostat comfort boundaries, and choose to use
                            cleaner energy
                          </p>
                        </div>
                      </div>
                      <div className="callouts f-tab-3">
                        <div className="callout-item">
                          <h4 className="callout-title">Cost tracking</h4>
                          <p className="callout-desc">
                            Provide customers with daily cost receipts segmented
                            by device, gas savings, and monthly savings
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">Rate optimization</h4>
                          <p className="callout-desc">
                            Help customers automatically manage their load
                            across time-of-use, demand and real-time rates
                          </p>
                        </div>
                        <div className="callout-item">
                          <h4 className="callout-title">Rate recommendation</h4>
                          <p className="callout-desc">
                            Encourage customers to switch to time-of-use rates
                            by showing savings derived directly from
                            personalized meter data
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="cta">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 col-lg-6">
                <div className="cta-title-area">
                  <h3 className="cta-title">Looking to help?</h3>
                  <p className="cta-des">Check out our careers page</p>
                </div>
              </div>
              <div className="col-md-7 col-lg-6">
                <div className="cta-rating-wrap">
                  <p>Make an impact and help us save the enviroment</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="faq section-padding">
          <a id="contact" style={{ display: 'block' }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-3"></div>
                <div className="col-lg-4 col-md-6">
                  <div className="faq-form">
                    <h3 className="faq-form-heading">
                      Request a demo to learn how Optiwatt can help you scale
                      your DR programs
                    </h3>
                    <HubSpotForm />
                  </div>
                </div>
              </div>
            </div>
          </a>
        </section>
      </main>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 order-last order-lg-first">
              <div className="footer-widget footer-logo-widget">
                <div className="footer-logo">
                  <img src="/img/logo/logo-colored-white.webp" alt="logo" />
                </div>
                <p className="copyright">
                  Copyrights © {new Date().getFullYear()}. <br />
                  All rights reserved by Optiwatt
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="footer-widget">
                <h4 className="footer-widget-title">About</h4>
                <ul>
                  <li>
                    <a href="https://optiwatt.applytojob.com" rel="noreferrer">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href="privacy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="tos">Terms of Service</a>
                  </li>
                  <li>
                    <a href="faq">FAQ</a>
                  </li>
                  <li>
                    <a href="partners">For Partners</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="footer-widget footer-address-widget">
                <h4 className="footer-widget-title">Contact</h4>
                <ul>
                  <li>
                    <img
                      src="landing-assets/thermostat/img/footer/location.png"
                      alt=""
                    />
                    <p>{optiwattConsts.contact.location}</p>
                  </li>
                  <li>
                    <a href={`mailto:${optiwattConsts.contact.email}`}>
                      <img
                        src="landing-assets/thermostat/img/footer/support.png"
                        alt=""
                      />
                      <p>{optiwattConsts.contact.email}</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.contact.website}>
                      <img
                        src="landing-assets/thermostat/img/footer/wifi.png"
                        alt=""
                      />
                      <p>{optiwattConsts.contact.website}</p>
                    </a>
                  </li>
                  <li>
                    <a href={`tel:${optiwattConsts.contact.phoneNumber}`}>
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          padding: '2px',
                        }}
                      >
                        <img
                          src="img/feathericons/primary/phone.webp"
                          alt="phone"
                          style={{ width: '100%' }}
                        />
                      </div>
                      <p>{optiwattConsts.contact.phoneNumber}</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="footer-widget footer-icon-widget">
                <h4 className="footer-widget-title">Contact</h4>
                <ul>
                  <li>
                    <a href={optiwattConsts.social.facebook}>
                      <i className="fab fa-facebook"></i>
                      <p className="social-media-name">Facebook</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.social.twitter}>
                      <i className="fab fa-twitter"></i>
                      <p className="social-media-name">Twitter</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.social.youtube}>
                      <i className="fab fa-youtube"></i>
                      <p className="social-media-name">YouTube</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.social.instagram}>
                      <img
                        src="landing-assets/thermostat/img/footer/ins.png"
                        alt=""
                      />
                      <p className="social-media-name">Instagram</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
